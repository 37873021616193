import React, { useState, useEffect } from 'react';
import ChaletResultsWebpart from './ChaletResultsWebpart';
import { LinearProgress } from '@mui/material';  // Importing Material UI Linear Progress

const ShortTransferWebpart = ({ loadingColor = 'primary' }) => {  // Added prop to pass custom colour
    const [chalets, setChalets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiUrl}/SearchByInspireMeAttributeLite/1`);
                const result = await response.json();
                setChalets(result);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [apiUrl]);

    if (loading) return <LinearProgress color={loadingColor} />;  // Replaced loading text with LinearProgress
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div>
            <div className="row no-gutters">
                <div className="col-md-7 text-left heading-section">
                    <div className="short-transfer-section p-3 lh-lg">
                        <h2>Discover Ski Chalets with Quick Airport Transfers - Your Slope-Side Stay Starts Here!</h2>
                        <p>
                            Find ski chalets that offer swift airport transfers, saving you time and ensuring more moments on the slopes. These handpicked chalets are ideal for hassle-free travel, bringing you closer to the mountains in no time. Explore our top-rated, conveniently located properties below!
                        </p>
                    </div>
                </div>
            </div>

            <div className="row no-gutters">
                {chalets.length > 0 ? (
                    chalets.slice(0, 8).map(chalet => (
                        <div key={chalet.chaletId} className="col-md-6 col-lg-3 ftco-animate fadeInUp ftco-animated">
                            <a
                                href={chalet.internalUrl}
                                className="block-5"
                                target="_blank"
                                rel="noreferrer"
                                style={{ backgroundImage: `url(${chalet.thumbnailImageUrl})` }}
                            >
                                <div className="text">
                                    <h3 className="heading">{chalet.name}</h3>
                                    <div className="post-meta">
                                        <span>
                                            {chalet.currencySymbol}
                                            {chalet.totalQueryCost.toLocaleString('en-GB', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                            {' '}for 7 days
                                        </span>
                                        <br />
                                        <span>{chalet.resort.name}</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))
                ) : (
                    <p>No chalets available</p>
                )}
            </div>
        </div>
    );
};

export default ShortTransferWebpart;
