import React from 'react';

const TopDestinationsWebpart = () => {
    return (

        <div className="container-fluid">

            <div className="row no-gutters">
                <div className="col-md-7 text-left heading-section">
                    <div class="popular-destinations-section p-3 lh-lg">
                        <h2>Explore Our Most Popular Destinations!</h2>
                        <p>Discover the most popular ski destinations, loved by our customers for their stunning alpine scenery and world-class slopes. Whether you're seeking a thrilling adventure or a peaceful retreat, these highly sought-after locations offer unforgettable experiences. Explore our top-rated destinations below!</p>
                    </div>

                </div>
            </div>
            <div className="row no-gutters">
                <div className="col-md-6 col-lg-3">
                    <a href="/Austria/Alpbach" className="block-5" id="tour_1">
                        <div className="text">
                            <h3 className="heading">Alpbach</h3>
                            <div className="post-meta">
                                <span></span>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="col-md-6 col-lg-3">
                    <a href="/Austria/Flachau" className="block-5" id="tour_2">
                        <div className="text">
                            <h3 className="heading">Flachau</h3>
                            <div className="post-meta">
                                <span></span>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="col-md-6 col-lg-3">
                    <a href="/France/Flaine" className="block-5" id="tour_3">
                        <div className="text">
                            <h3 className="heading">Flaine</h3>
                            <div className="post-meta">
                                <span></span>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="col-md-6 col-lg-3">
                    <a href="/France/Tignes" className="block-5" id="tour_4">
                        <div className="text">
                            <h3 className="heading">Tour Tignes</h3>
                            <div className="post-meta">
                                <span></span>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="col-md-6 col-lg-3">
                    <a href="/Switzerland/Verbier" className="block-5" id="tour_5">
                        <div className="text">
                            <h3 className="heading">Verbier</h3>
                            <div className="post-meta">
                                <span></span>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="col-md-6 col-lg-3">
                    <a href="/Austria/St.%20Anton%20am%20Arlberg" className="block-5" id="tour_6">
                        <div className="text">
                            <h3 className="heading">St Anton</h3>
                            <div className="post-meta">
                                <span></span>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="col-md-6 col-lg-3">
                    <a href="/Italy/Courmayeur" className="block-5" id="tour_7">
                        <div className="text">
                            <h3 className="heading">Courmayeur</h3>
                            <div className="post-meta">
                                <span></span>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="col-md-6 col-lg-3">
                    <a href="/France/Chamonix" className="block-5" id="tour_8">
                        <div className="text">
                            <h3 className="heading">Chamonix</h3>
                            <div className="post-meta">
                                <span></span>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>

    );
};

export default TopDestinationsWebpart;
