import React, { Component } from 'react';
import { SearchBar } from '../NavMenu/SearchBar';
import ShortTransferWebpart from '../DynamicPages/Webparts/ShortTransferWebpart';
import TopDestinationsWebpart from '../DynamicPages/Webparts/TopDestinations';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMediaQuery } from '@mui/material';

// Functional Component to handle responsive rendering
const ResponsiveAccordion = ({ children, title, defaultExpanded = false }) => {
    // Hook to detect if the screen width is mobile size (600px or less)
    const isMobile = useMediaQuery('(max-width:600px)');

    // Conditionally render Accordion for mobile and standard layout for larger screens
    if (isMobile) {
        return (
            <Accordion defaultExpanded={defaultExpanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${title.toLowerCase().replace(/\s+/g, '-')}-content`}
                    id={`${title.toLowerCase().replace(/\s+/g, '-')}-header`}
                >
                    <Typography>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails>{children}</AccordionDetails>
            </Accordion>
        );
    }

    // For non-mobile devices, render content directly without Accordion
    return <div>{children}</div>;
};

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <div>
                <section className="home-slider owl-carousel">
                    <div className="slider-item" id="bg_1">
                        <div className="overlay"></div>
                        <div className="container">
                            <div className="row slider-text align-items-center">
                                <div className="col-md-7 col-sm-12 ftco-animate">
                                    <h1 className="mb-3">Experience the best trip ever</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Additional slider items unchanged */}
                </section>

                <SearchBar location={this.props.location} />

                {/* Short Transfer Section - Accordion only for mobile */}
                <ResponsiveAccordion title="Discover Ski Chalets with Quick Airport Transfers - Your Slope-Side Stay Starts Here">
                    <ShortTransferWebpart />
                </ResponsiveAccordion>

                {/* Top Destinations Section - Accordion only for mobile */}
                <ResponsiveAccordion title="Explore Our Most Popular Destinations!" defaultExpanded={true}>
                    <TopDestinationsWebpart />
                </ResponsiveAccordion>
            </div>
        );
    }
}
